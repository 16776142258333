<script setup lang="ts">
import { UITag } from '@groover-dev/groover-ui'

import DatoCardTemplate from '~/components/dato/CardTemplate.vue'

import { useFormatNumber } from '~/composables/useFormatNumber'

import { isNonNullAndDefined } from '~/utils/type-guards'

import type { CuratorCardFragment } from '~/graphql/generated'

type Props = CuratorCardFragment

const props = defineProps<Props>()

const { formatNumberCompact } = useFormatNumber()

const description = computed<string | undefined>(() => {
  return props.descriptionOverride || props.curator?.description || undefined
})

const followerCount = computed(() => {
  if (!isNonNullAndDefined(props.curator?.spotifyFollowers)) return undefined
  return formatNumberCompact(props.curator?.spotifyFollowers)
})

const tags = computed<string[]>(() => {
  const tags =
    props.curator?.tags?.split(',').reduce<string[]>((accumulator, tag) => {
      const cleanedTag = tag.trim()

      if (cleanedTag.length) accumulator.push(cleanedTag)
      return accumulator
    }, []) || []

  if (followerCount.value && props.prioritizeSpotifyFollowersDisplay) return []
  return tags.slice(0, 1)
})
</script>

<template>
  <DatoCardTemplate
    :responsive-image="curator?.squareProfileImage?.responsiveImage"
    :link="curator?.profileLink"
  >
    <template #title>{{ curator?.name }}</template>
    <template #subtitle>{{ curator?.kind }}</template>
    <template #tags>
      <UITag
        v-if="followerCount && !tags.length"
        color="alt"
        :text="
          $t('common.followerCount', {
            followerCount,
          })
        "
        icon-left-name="logos:spotify-icon" />
      <UITag v-for="(tag, index) in tags" :key="index" :text="tag" color="alt"
    /></template>
    <template #description>{{ description }}</template>
  </DatoCardTemplate>
</template>
